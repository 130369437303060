import React from 'react';
import '../css/Home.css';
import { Link } from 'react-router-dom';

// Assets png's
import fiverr from '../assets/fiverr.webp';
import linkein from '../assets/linkein.webp';
import upwork from '../assets/upwork.webp';
import whatsapp from '../assets/whatsapp.webp';

// profile pic
import profile from '../assets/pic.webp';

// support pic
import fast from '../assets/fast.webp';
import satis from '../assets/satis.webp';
import support from '../assets/support.webp';

import VE from '../assets/VE.webp';
import WA from '../assets/WA.webp';
import WP from '../assets/WP.webp';
import BC from '../assets/BC.webp';
import SC from '../assets/SC.webp';
import SW from '../assets/SW.webp';

function Home({ homeRef, servicesRef, aboutMeRef, workExperienceRef }) {
  return (
    <div className="home" ref={homeRef}>
      <div className="upper-section">
        <div className="text-container">
          <h1>
            <span className="heading-highlight">Shah Wahaj</span>
            <br />
            Project Manager
          </h1>
          <p>
          I oversee and coordinate a diverse team of talented individuals, ensuring the successful delivery of web development, graphics design, android development and computer-related solutions. With a strong focus on meeting client needs and exceeding expectations, I manage projects from inception to completion, optimizing workflows, maintaining clear communication, and driving innovation within our services. My role involves balancing technical expertise with leadership, ensuring our projects are completed on time and to the highest standards.          </p>
          <div className="social-icons">
            <a href="https://www.upwork.com/freelancers/~014ba0347b294ab3a2" target="_blank" rel="noopener noreferrer" className="icon upwork">
              <img src={upwork} alt="Upwork" className="icon-image" />
            </a>
            <a href="https://www.fiverr.com/xhahwahaj?up_rollout=true" target="_blank" rel="noopener noreferrer" className="icon fiverr">
              <img src={fiverr} alt="Fiverr" className="icon-image" />
            </a>
            <a href="https://www.linkedin.com/in/shah-wahaj-5216b5253/" target="_blank" rel="noopener noreferrer" className="icon linkedin">
              <img src={linkein} alt="LinkedIn" className="icon-image" />
            </a>
            <a href="https://wa.me/923407676501?text=Hello!%20I'm%20interested%20in%20your%20services.%20Can%20we%20talk?" target="_blank" rel="noopener noreferrer" className="icon whatsapp">
              <img src={whatsapp} alt="WhatsApp" className="icon-image" />
            </a>
          </div>
        </div>
        <div className="image-container">
          <img src={profile} alt="Visual Representation" />
        </div>
      </div>

      <div className="lower-section">
        <div className="icon-section">
          <img src={fast} alt="Fast Delivery" className="icon-image" />
          <p>Fast Delivery</p>
        </div>
        <div className="icon-section">
          <img src={satis} alt="100% Satisfaction" className="icon-image" />
          <p>100% Satisfaction</p>
        </div>
        <div className="icon-section">
          <img src={support} alt="24/7 Support" className="icon-image" />
          <p>24/7 Support</p>
        </div>
      </div>

      <div id="services-section" className="skills-section" ref={servicesRef}>
        <h2>Our Services</h2>
        <div className="skills-grid">
          <div className="skills-row">
            <div className="skill-column">
              <div className="skill-content">
                <div className="skill-button">
                  <img src={VE} alt="Motion Graphics" className="skill-image" />
                  <Link to="/motion-graphics" className="btn-contact">Explore</Link>
                </div>
                <div className="skill-info">
                  <h3>Motion Graphics</h3>
                  <p>Create dynamic and engaging motion graphics with expert After Effects animation.</p>
                </div>
              </div>
            </div>
            <div className="skill-column">
              <div className="skill-content">
                <div className="skill-button">
                  <img src={WA} alt="Web Application" className="skill-image" />
                  <Link to="/explore/Web Application" className="btn-contact">Explore</Link>
                </div>
                <div className="skill-info">
                  <h3>Web Application</h3>
                  <p>Develop responsive and interactive web applications using the powerful React framework.</p>
                </div>
              </div>
            </div>
            <div className="skill-column">
              <div className="skill-content">
                <div className="skill-button">
                  <img src={BC} alt="Blockchain Integration" className="skill-image" />
                  <Link to="/explore/Blockchain Integration" className="btn-contact">Explore</Link>
                </div>
                <div className="skill-info">
                  <h3>Blockchain Integration</h3>
                  <p>Integrate Ethereum blockchain technology to enhance security and functionality in your applications.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="skills-row">
            <div className="skill-column">
              <div className="skill-content">
                <div className="skill-button">
                  <img src={SC} alt="Smart Contracts" className="skill-image" />
                  <Link to="/explore/Smart Contracts" className="btn-contact">Explore</Link>
                </div>
                <div className="skill-info">
                  <h3>Smart Contract Writing</h3>
                  <p>Write and deploy secure and efficient smart contracts using Solidity for blockchain solutions.</p>
                </div>
              </div>
            </div>
            <div className="skill-column">
              <div className="skill-content">
                <div className="skill-button">
                  <img src={WP} alt="WordPress eCommerce Store" className="skill-image" />
                  <Link to="/explore/Wordpress" className="btn-contact">Explore</Link>
                </div>
                <div className="skill-info">
                  <h3>E-commerce Store</h3>
                  <p>Build and customize fully functional eCommerce stores with WordPress for seamless online sales.</p>
                </div>
              </div>
            </div>
            <div className="skill-column">
              <div className="skill-content">
                <div className="skill-button">
                  <img src={SW} alt="SolidWorks" className="skill-image" />
                  <Link to="/solidworks" className="btn-contact">Explore</Link>
                </div>
                <div className="skill-info">
                  <h3>SolidWorks</h3>
                  <p>Design and create precise 3D models with SolidWorks for engineering and product development.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="who-am-i-section">
        <h2>Who Is Shah Wahaj?</h2>
        <div className="who-am-i-content">
          <div className="about-me" ref={aboutMeRef}>
            <div className="about-me-section">
              <h2>About Me</h2>
              <p>
              A skilled professional with expertise in business, project, and management. I have a diverse skill set of managing the software development team. My ability to lead cross-functional teams and drive high-impact projects ensures operational excellence and business growth. Here’s a bit more about myself:
              </p>

              <div className="education">
                <h2>Education</h2>
                <p>I graduated in Business Management from Air University. During my time there, I learned Human resource management, project management, Business Ethics, Business comunication, organizational behavior and many other field relevent subjects.</p>
              </div>
              <div className="work-experience" ref={workExperienceRef}>
                <h2>Work Experience</h2>
                <p>With over four years experience spans leading strategic initiatives, optimizing operations, and managing complex projects. With a strong foundation in aviation operations and business leadership, I ensure efficiency, compliance, and high performance across diverse industries. mentioned below are the expertise of my team.</p>
              </div>
              <div className="skills">
                <h3>Programming Languages</h3>
                <ul>
                  <li>C++</li>
                  <li>JavaScript</li>
                  <li>Solidity</li>
                </ul>
              </div>

              <div className="libraries">
                <h3>Libraries</h3>
                <ul>
                  <li>React.js</li>
                  <li>Ethers.js</li>
                </ul>
              </div>

              <div className="frameworks">
                <h3>Frameworks</h3>
                <ul>
                  <li>Node.js</li>
                  <li>Hardhat.js</li>
                </ul>
              </div>

              <div className="animation-tools">
                <h3>Animation Tools</h3>
                <ul>
                  <li>Adobe After Effects</li>
                  <li>Adobe Photoshop</li>
                  <li>Adobe Audition</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
