import React, { useState } from 'react';
import '../css/Lower.css'; // Import the CSS for the Footer

import logo from '../assets/logo.webp';


// Assets png's
import fiverr from '../assets/fiverr.webp';
import linkein from '../assets/linkein.webp';
import upwork from '../assets/upwork.webp';
import whatsapp from '../assets/whatsapp.webp';


function Footer() {
  const [openSection, setOpenSection] = useState(null);

  const toggleDropdown = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section logo-section">
          <img src= {logo} alt="Meliora Aesthetic Logo" className="footer-logo" />
          <p>Building Digital Experiences, One Line of Code at a Time.</p>
        </div>

        <div className={`footer-section ${openSection === 'about' ? 'open' : ''}`}>
          <h3 className="dropdown-heading" onClick={() => toggleDropdown('about')}>
            FAQ
            <span className={`arrow ${openSection === 'about' ? 'open' : ''}`}></span>
          </h3>
          <div className="dropdown-content">
            <p>Feel free to contact me if you have any queries.</p>
          </div>
        </div>

        <div className={`footer-section ${openSection === 'services' ? 'open' : ''}`}>
          <h3 className="dropdown-heading" onClick={() => toggleDropdown('services')}>
            Services
            <span className={`arrow ${openSection === 'services' ? 'open' : ''}`}></span>
          </h3>
          <div className="dropdown-content">
            <p>specialized in Website development, animations, and blockchain solutions</p>
          </div>
        </div>

        <div className={`footer-section ${openSection === 'contact' ? 'open' : ''}`}>
          <h3 className="dropdown-heading" onClick={() => toggleDropdown('contact')}>
            Contact Us
            <span className={`arrow ${openSection === 'contact' ? 'open' : ''}`}></span>
          </h3>
          <div className="dropdown-content">
            <div className="contact-details">
              <div className="footer-social-icons">
              <a href="https://www.linkedin.com/in/shah-wahaj-5216b5253/" target="_blank" rel="noopener noreferrer" className="icon linkedin">
              <img src= {linkein} alt="LinkedIn" className="icon-image" />
                </a>
                <a href="https://www.fiverr.com/xhahwahaj?up_rollout=true" target="_blank" rel="noopener noreferrer" className="icon fiverr">
                <img src= {fiverr} alt="Fiverr" className="icon-image" />
                </a>
                <a href="https://www.upwork.com/freelancers/~014ba0347b294ab3a2" target="_blank" rel="noopener noreferrer" className="icon upwork">
                <img src= {upwork} alt="Upwork" className="icon-image" />
                </a>
                <a href="https://wa.me/923407676501?text=Hello!%20I'm%20interested%20in%20your%20services.%20Can%20we%20talk?" target="_blank" rel="noopener noreferrer" className="icon whatsapp">
                <img src= {whatsapp} alt="WhatsApp" className="icon-image" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Wahaj's Portfolio, All Rights Reserved</p>
      </div>
    </footer>
  );
}

export default Footer;
