import React, { useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Lower';
import Home from './components/Home';
import Explore from './project/explore';
import MotionGraphics from './project/motionGraphics';
import SolidWorks from './project/solidWorks';

import Login from './login/login';

function App() {
  const homeRef = useRef(null);
  const servicesRef = useRef(null);
  const aboutMeRef = useRef(null);
  const workExperienceRef = useRef(null);

  const scrollToHome = () => {
    if (homeRef.current) {
      homeRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToServices = () => {
    if (servicesRef.current) {
      servicesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToAboutMe = () => {
    if (aboutMeRef.current) {
      aboutMeRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToWorkExperience = () => {
    if (workExperienceRef.current) {
      workExperienceRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Router>
      <div className="App">
        <Header 
          onHomeClick={scrollToHome} 
          onServicesClick={scrollToServices} 
          onAboutMeClick={scrollToAboutMe} 
          onWorkExperienceClick={scrollToWorkExperience} 
        />
        <Routes>
          <Route path="/" element={
            <Home 
              homeRef={homeRef} 
              servicesRef={servicesRef} 
              aboutMeRef={aboutMeRef} 
              workExperienceRef={workExperienceRef} 
            />} 
          />
          <Route path="/explore/:serviceName" element={<Explore />} /> 
          <Route path="/motion-graphics" element={<MotionGraphics />} /> 
          <Route path="/solidworks" element={<SolidWorks />} /> 

          <Route path="/login"  element={<Login />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;