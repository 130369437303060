import React, { useState, useEffect } from "react";
import { ref, getDownloadURL, listAll } from "firebase/storage";
import { storage } from "../firebase";
import "../project-css/motionGraphics.css"; // Ensure this file is correctly linked
import logo from '../assets/logo.webp'; // Assuming you have a logo for the preloader

function MotionGraphics() {
  const [videoUrls, setVideoUrls] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true); // Track loading state

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const videosListRef = ref(storage, "solidworks/");
        const response = await listAll(videosListRef);
        const urls = await Promise.all(
          response.items.map(async (item) => {
            try {
              return await getDownloadURL(item);
            } catch (error) {
              console.error("Error getting download URL:", error);
              setError("Error getting download URL: " + error.message);
              return null;
            }
          })
        );
        setVideoUrls(urls.filter((url) => url !== null));
      } catch (error) {
        console.error("Error listing videos:", error);
        setError("Error listing videos: " + error.message);
      } finally {
        setLoading(false); // Stop loading after videos are fetched
      }
    };

    fetchVideos();
  }, []);

  return (
    <div className="motion-graphics-container">
      {loading ? (
        <div className="preloader">
          <img src={logo} alt="Loading..." className="preloader-logo" />
        </div>
      ) : (
        <>
          {error && <p className="error-message">{error}</p>}
          <div className="video-container">
            {videoUrls.map((url, index) => (
              <div key={url} className="video-wrapper">
                <video src={url} controls />
                <p className="video-label">{`SW ${String(index + 1).padStart(3, '0')}`}</p>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default MotionGraphics;
