import React, { useState } from 'react';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebase';
import { v4 as uuidv4 } from 'uuid';
import CustomMessage from './customMessage'; // Ensure this file is correctly linked
import "./videoUpload.css"; // Ensure this file is correctly linked

const VideoUpload = ({ isAuthenticated }) => {
  const [videoUpload, setVideoUpload] = useState(null);
  const [status, setStatus] = useState('idle');
  const [folder, setFolder] = useState('motion graphics'); // Default folder is 'motion graphics'

  const uploadFile = () => {
    if (!videoUpload) return;
    setStatus('uploading');
    
    // Create a reference with the selected folder and unique filename
    const videoRef = storageRef(storage, `${folder}/${videoUpload.name + uuidv4()}`);
    uploadBytes(videoRef, videoUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        console.log('Uploaded video URL:', url);
        setStatus('success');
      }).catch(() => {
        setStatus('error');
      });
    }).catch(() => {
      setStatus('error');
    });
  };

  // Only render the upload container if authenticated
  if (!isAuthenticated) {
    return <p>Please log in to upload videos.</p>;
  }

  return (
    <div className="upload-container">
      {/* Dropdown to select folder */}
      <label>Select folder:</label>
      <select value={folder} onChange={(e) => setFolder(e.target.value)}>
        <option value="motion graphics">Motion Graphics</option>
        <option value="solidworks">SolidWorks</option>
        {/* You can add more options for other folders here */}
      </select>

      {/* File input for video */}
      <input
        type="file"
        accept="video/*"
        onChange={(event) => {
          setVideoUpload(event.target.files[0]);
        }}
      />
      
      {/* Upload button */}
      <button onClick={uploadFile}>Upload Video</button>

      {/* Status message component */}
      <CustomMessage status={status} />
    </div>
  );
};

export default VideoUpload;
