// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyAwCFPWiJfLecJ-Ka4vXe6EbrAjTxH3cUc",
  authDomain: "portfolio-830e5.firebaseapp.com",
  databaseURL: "https://portfolio-830e5-default-rtdb.firebaseio.com",
  projectId: "portfolio-830e5",
  storageBucket: "portfolio-830e5.appspot.com",
  messagingSenderId: "1055787149400",
  appId: "1:1055787149400:web:69c4e4a1bd820983eac22d"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const database = getDatabase(app);